<template>
    <div>
        <the-header/>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>NMRA Certified Online Employee Training</h2>
                    <p>Online employee training that enhances customer satisfaction, reduces risks, and boosts revenue</p>
                </div>
            </div>
        </section>
        <div class="content-area">
            <div class="container">
                <div class="row pt-6 pb-6 justify-content-center">
                    <div class="col-md-6 col-lg-4">
                        <div class="training-card">
                            <div class="training-img">
                                <img src="img/signup/image1.png" class="img-fluid" />
                            </div>
                            <div class="training-content">
                                <h3>Individual Sign Up</h3>
                                <router-link class="btn ctm-btn" to="/nmra-register">Click Here</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="training-card">
                            <div class="training-img">
                                <img src="img/signup/image2.png" class="img-fluid" />
                            </div>
                            <div class="training-content">
                                <h3>Company Sign Up</h3>
                                <router-link class="btn ctm-btn" to="/nmra-corporate-register">Click Here</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <the-footer/>
    </div>
</template>
<script>
import "sweetalert2/src/sweetalert2.scss";
import '@/nmra/partials/style.css';
import '@/nmra/partials/responsive.css';

import TheHeader from '@/nmra/partials/Header.vue';
import TheFooter from '@/nmra/partials/Footer.vue';

export default {
    components: {
        TheHeader,
        TheFooter
    },
    data() {
        return {};
    },
    created: function () {
    },
    methods: {
        individualSinup: function () {
            this.$router.push("/nmra-register");
        },
        companySignup: function () {
            this.$router.push("/nmra-corporate-register");
        }
    }
};
</script>
<style scoped>
.banner-caption h2 {
    font-size: 30px;
}

.training-card {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%);
    transition: all .22s;
    transform: translateZ(0);
}
.training-card:hover {
    box-shadow: 1px 5px 20px rgb(0 0 0 / 30%);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
}
.training-card:hover h3 {
    color: #b73e23;
}
.training-content h3 {
    color: #284A5B;
    font-size: 24px;
    margin-bottom: 20px;
}
.training-content {
    padding: 20px;
    min-height: 160px;
}

.training-img img {
    width: 100%;
    height: auto;
}

.pb-6 {
    padding-bottom: 7rem;
}
.content-area{
    background: #fff;
    /* min-height: 62vh; */
}
body:has(.training-img) #footer-main[data-v-7b7d7750] {
    border-top: 5px solid #b73e23;
    height: 100px;
    padding-top: 25px;
     position: fixed;
}


body:has(.training-img) .content-area[data-v-0baf4b20] {
    background: #fff;
    min-height: 62vh !important;
}
.ctm-btn {
    background-color: #b73e23;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 14px;
    margin-right: 5px;
}
.login-inputs {
    padding-top: 20px;
}
.login{
    margin-right: 0px !important;
}

@media only screen and (max-width:991px) and (orientation: portrait) {
.content-area[data-v-0baf4b20] {
    background: #fff;
    min-height: 60vh !important;
    display: flex;
    align-items: center;
}
body:has(.training-img) .content-area[data-v-0baf4b20][data-v-0baf4b20] {
    min-height: 60vh !important;
}
body:has(.training-img) #footer-main[data-v-7b7d7750][data-v-0baf4b20] {
    height: 150px;
 }


}
@media only screen and (max-width:768px) {
    /* .ctm-btn[data-v-0baf4b20] {  
          margin-top: -30px;

} */
.content-area[data-v-0baf4b20] {
    background: #fff;
    min-height: 60vh !important;
    display: flex;
    align-items: center;
}
body:has(.training-img) .content-area[data-v-0baf4b20][data-v-0baf4b20] {
    min-height: 60vh !important;
}
body:has(.training-img) #footer-main[data-v-7b7d7750][data-v-0baf4b20] {
    height: 150px;
 }
 .training-content .ctm-btn{
    float: unset;
 }
}
@media (max-width:575px) {
    .training-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ctm-btn[data-v-0baf4b20][data-v-0baf4b20] {
    margin-top:0;
}
.training-content h3[data-v-0baf4b20] {
    margin-bottom: 0 !important;
}
body:has(.training-img) #footer-main[data-v-7b7d7750] {
     position: unset;
}


}
</style>
